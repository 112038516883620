<template>
  <detail :reply-data-list="replyDataList" :detail-data="detailData" />
</template>

<script>
import detail from './detail2.vue'
export default {
  components: { detail },
  data() {
    return {
      detailData: [
        { id: 'title', label: '标题' },
        { id: 'createTime', label: '时间' },
        { id: 'context', label: '详情', type: 'rich' }
      ],
      replyDataList: [
        { id: 'replyPbName', label: '答复单位' },
        { id: 'replyTime', label: '答复时间' },
        { id: 'replyContext', label: '答复详情' }
      ],
      methodPath: { // 接口必传
        getInfoFromId: 'home/ConsultingGetConsInfo' // 根据id获取详情的接口
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
