<template>
  <div class="myDetail">
    <div class="nav">
      首页 >
      <span class="click" @click="$go(`/ConsultingList?type=${param.type}`)">{{
        param.type
      }}</span>
      > 咨询详情
    </div>
    <div class="detailOut">
      <div class="texta">网友留言</div>
      <div class="detail">
        <div v-for="(item, index) in newDataList" :key="index" class="item">
          <div
            v-if="item.type !== 'title'"
            class="label"
            :style="{ width: labelWidth }"
          >
            {{ item.label }}
          </div>
          <!-- 富文本 -->
          <div v-if="item.type === 'rich'" class="normal rich" v-html="$setContent(item.value)" />
          <!-- 常用文本 -->
          <div v-else class="normal">{{ item.value }}</div>
        </div>
      </div>
      <div v-if="replyData.length">
        <div class="texta">答复详情</div>
        <div class="detail">
          <div v-for="(item, index) in replyData" :key="index" class="item">
            <div
              v-if="item.type !== 'title'"
              class="label"
              :style="{ width: labelWidth }"
            >
              {{ item.label }}
            </div>
            <!-- 富文本 -->
            <div
              v-if="item.type === 'rich'"
              class="normal rich"
              v-html="$setContent(item.value)"
            />
            <!-- 常用文本 -->
            <div v-else class="normal">{{ item.value }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { statusData } from '@/views/personal/page/common.js'
export default {
  props: {
    detailData: {
      // 详情内容
      type: Array,
      default: () => []
    },
    labelWidth: {
      type: String,
      default: '200px'
    },
    replyDataList: {
      // 回复内容
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      param: {},
      newDataList: [],
      replyData: [],
      statusData, // 根据状态做出按钮的隐藏
      strStatus: '' // 状态
    }
  },
  computed: {
    methodPath() {
      return this.$parent.methodPath
    }
  },

  created() {
    this.getInit()
  },

  methods: {
    getInit() {
      this.param = this.$getParams()
      this.$store
        .dispatch(this.methodPath.getInfoFromId, { id: this.param.id })
        .then((res) => {
          if (res.code === 0) {
            this.newDataList = this.detailData.map((item) => {
              item.id && (item.value = res.data[item.id] || '--')
              return item
            })
            this.replyData = this.replyDataList.map((item) => {
              item.value = res.data[item.id]
              return item
            })
            this.replyData.filter((item) => item.value).length ||
              (this.replyData = [])
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.nav,
span {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  width: 1000px;
  margin: 20px auto 19px auto;
}
.detailOut {
  background: #fff;
  padding: 34px 32px;
  width: 1000px;
  margin: auto;
}
.detail {
  border-right: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 40px;

  .item {
    display: flex;
    .label {
      background: #f6f8fa;
      border-top: 1px solid #e0e0e0;
      border-right: 1px solid #e0e0e0;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      padding: 16px 20px;
      border-top: 1px solid #e0e0e0;
      line-height: 32px;
      text-align: center;
      word-break: break-all;
    }
    .normal {
      flex: 1;
      padding: 16px 20px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      border-top: 1px solid #e0e0e0;
      line-height: 32px;
      word-break: break-all;
    }
  }
}
.handle {
  background: #f6f8fa;
  padding: 1px 20px 28px 20px;
  .item {
    display: flex;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    margin-top: 27px;
    .label {
      width: 106px;
    }
  }
}
.line {
  width: 1056px;
  height: 1px;
  background: #e0e0e0;
}
.title {
  text-align: center;
}

.myDetail {
  background: #f6f8fa;
  flex: 1;
}

.texta {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: var(--theme);
  margin-bottom: 20px;
}
</style>
